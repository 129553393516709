//Mobile------------------------------------------------------------------------------------------------------
#magic-cursor {
	#ball {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		&.small-icon {
			img.cursor-img {
				width: 24px;
			}
		}
	}
	img.cursor-img{
		width: 70%;
	}
	p {
		height: auto;
	}
	p.first {
		padding-top: 0;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}