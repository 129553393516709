body {
	font-family: var(--font-primary);
	//don't set a font size, most browsers use 16px
}

p,
ul,
ol {
	font-size: var(--p-font-size);
	line-height: var(--p-line-height);
	color: var(--p-color);
	margin-top: 0;
	margin-bottom: var(--p-margin-bottom);
}

.ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 1.5rem;
	font-family: var(--heading-font);
	font-weight: var(--heading-weight);
	line-height: var(--heading-line-height);
	letter-spacing: var(--heading-letter-spacing);
}

hr {
	border: none;
	height: var(--hr-size);
	background-color: var(--hr-color);
	margin-top: var(--hr-margin);
	margin-bottom: var(--hr-margin);
}

// .h# 1-6 classes fontsize
@for $i from 1 through 6 {
	.h#{$i} {
		font-size: var(--h#{$i}-font-size);
	}
}

.fs-xs {
	font-size: 0.75em;
}

.fs-sm {
	font-size: 0.875em;
}

.fs-lg {
	font-size: 1.4em;
}

.fs-xl {
	font-size: 1.6em;
}

.ff-primary {
	font-family: var(--font-primary);
}

.ff-secondary {
	font-family: var(--font-secondary);
}

.ff-mono {
	font-family: var(--font-mono);
}
