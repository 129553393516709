//Mobile------------------------------------------------------------------------------------------------------
.portfolio-section {
	padding-inline: 30px;
	position: relative;
	.portfolio-row {
		margin-bottom: 80px;
	}
	.img-wrapper {
		margin-bottom: 30px;
	}
	h3 {
		font-size: clamp(2rem,6.3vw,52px);
		margin-bottom: 10px;
		position: relative;
	}
	h4 {
		font-family: var(--font-primary);
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 2.8px;
		margin-bottom: 40px;
		position: relative;
		line-height: 1.333333;
		span {
			color: var(--color-primary);
		}
	}
	p {
		font-size: 16px;
		position: relative;
	}
	img {
		width: 100%;
		height: auto;
		&.desktop {
			display: none;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.portfolio-section {
		img {
			&.mobile {
				display: none;
			}
			&.desktop {
				display: block;
			}
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.portfolio-section {
		padding-inline: 120px;
		.portfolio-row {
			padding-bottom: 96px;
			margin-bottom: 96px;
			///border-bottom: 1px solid #CCC;
			transition: all 0.5s;
			position: relative;
			&.hidden-row {
				opacity: 0;
			}

		}
		.img-wrapper {
			margin-bottom: 0px;
			position: fixed;
			inset: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			pointer-events: none;
			transition: all 0.5s;
			/*&:after {
				content: '';
				display: block;
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0.3);
			}*/
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.left-text {
			//margin-right: 10%;
			&:after {
				content: '';
				position: absolute;
				bottom: -96px;
				top: auto;
				height: 1px;
				left: 0;
				right: -120px;
				width: calc(100% + 120px);
				background-color: #CCC;
				transition: all 0.3s;
			}
		}
		.right-text {
			width: 40%;
			pointer-events: none;
		}
		.porftolio-texts {
			position: relative;
			display: flex;
			justify-content: space-between;
		}
		h4 {
			margin-bottom: 0;
		}
	}
	.portfolio-row {
		.left-text {
			flex-grow: 1;
		}
		&.hovered {
			.left-text {
				&:after {
					opacity: 0;
				}
				.img-wrapper {
					opacity: 1;
				}
				h3,h4 {
					opacity: 0;
					//color: #FFF !important;

				}
			}
			.right-text p {
				//color: #FFF;
				opacity: 0;
			}
		}

	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.portfolio-row {
		h3,h4,p {
			transition: all 0.5s;
		}
	}
}
