//Mobile------------------------------------------------------------------------------------------------------
.title-text {
	padding: 80px 20px;
	margin-left: 32px;
	h2 {
		font-size: 28px;
		font-weight: 600;
		line-height: 1.25;
		letter-spacing: -0.84px;
		margin-bottom: 32px;
		&:before {
			content: '/ ';
			color: var(--color-primary);
		}
		span {
			display: inline-block;
		}
	}
	p,
	ul,
	ol,
	blockquote {
		font-size: 20px;
		line-height: 1.75;
	}
	ul,
	ol {
		li {
			&::marker {
				color: var(--color-primary);
				font-weight: bold;
			}
		}
	}
	blockquote {
		padding: 0;
		margin-block: 24px;
		&:before,
		&:after {
			color: var(--color-primary);
			font-family: var(--heading-font);
			font-weight: var(--heading-weight);
			font-size: 3rem;
			line-height: 0;
		}
		&:before {
			content: "“";
			left: -0.5em;
			top: 0.25em;
			position: absolute;
		}
		&:after {
			position: relative;
			content: "”";
			left: 0.1em;
			bottom: -0.65em;
			top: auto;
		}
	}
	p {
		&:last-child {
			margin-top: 0;
		}
	}
	.list {
		padding: 0;
		margin: 40px 0 0;
		list-style-type: none;
		li:not(:last-child) {
			margin-bottom: 32px;
		}
	}
	.name,
	.position {
		display: block;
	}
	.name {
		margin-bottom: 4px;
	}
	.position {
		display: block;
		color: #999;
		font-size: 10px;
		font-weight: 700;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.title-text {
		padding-inline: 60px;
		margin-left: 0;
		.content {
			display: flex;
			h2 {
				width: 33.3333%;
				padding-right: 30px;
			}
			.text {
				width: 66.6666%;
			}
		}
		.list {
			margin-left: 33.3333%;
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			li {
				width: calc(50% - 16px);
				margin-bottom: 0 !important;
			}
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.title-text {
		padding-inline: 120px;
		padding-top: 132px;
		padding-bottom: 160px;
		.content {
			display: flex;
			h2 {
				padding-right: 60px;
			}
		}
		.list {
			margin-top: 120px;
			margin-left: 33.3333%;
			gap: 32px;
			li {
				width: calc(33.3333% - 32px);
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
	.title-text {
		.content {
			h2 {
				padding-right: var(--padding-h-desk);
			}
		}
		.list {
			gap: var(--padding-h-desk);
			li {
				width: calc(33.3333% - var(--padding-h-desk));
			}
		}
	}
}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
