//Mobile------------------------------------------------------------------------------------------------------
.image-text-pinned {
	position: relative;
	.img-container {
		height: 0;
		padding-bottom: 100%;
		background-size: cover;
		background-repeat: none;
		transition: none;
	}
	.content {
		padding: 80px 20px;
		margin-left: 32px;
	}
	h2 {
		margin-bottom: 40px;
		position: relative;
		font-size: clamp(32px,3.61vw,38px);
		line-height: 0.92;
		&:before,
		&:after {
			color: var(--color-primary);
		}
		&:before {
			content: "“";
			left: -0.5em;
			top: -0.25em;
			position: absolute;
		}
		&:after {
			position: relative;
			content: "”";
			left: 0.1em;
			bottom: -0.65em;
			top: auto;
		}
	}
	.signature {
		margin-top: 32px;
		margin-left: -16px;
		height: 34px;
		width: auto;
	}
	.position {
		font-size: 14px;
		letter-spacing: 2.8px;
		text-transform: uppercase;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.image-text-pinned {
		display: flex;
		flex-direction: row-reverse;
		.img-container,
		.content {
			width: 50%;
			flex-shrink: 0;
		}
		.img-container {
			position: absolute;
			padding-bottom: 0;
			height: 100%;
		}
		.content {
			margin-left: 0;
			padding-inline: 60px;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.image-text-pinned {
		align-items: center;
		min-height: 100vh;
		.img-container,
		.content {

		}
		.content {
			padding-inline: 120px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
