//Mobile------------------------------------------------------------------------------------------------------
.news {
	margin-bottom: 80px;
	img {
		width: 100%;
	}
	.box {
		margin-bottom: 28px;
	}
	h2 {
		margin-bottom: 24px;
		font-size: 28px;
	}
	p {
		color: rgba(38, 38, 38, 0.70);
		font-size: 16px;
		line-height: 32px;
		margin-bottom: 20px;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}