//Mobile------------------------------------------------------------------------------------------------------
.preloader-wrap {
	background-color: var(--body-bg-dark);
	.preloader-list {
		span {
			color: var(--color-primary);
		}
	}
	.background {
		position: absolute;
		inset: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 75%;
			max-width: 600px;
			height: auto;
			opacity: 0.05;
			height: auto;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
