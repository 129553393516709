//Mobile------------------------------------------------------------------------------------------------------
.content-row {
	&.vertical-center {
		.img-h-scroll .title-moving-outer {
			bottom: inherit;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&.padding-top{
		padding-top: 80px;
	}
	&.padding-bottom{
		padding-bottom: 80px;
	}
	.content-lines {
		position: absolute;
		inset: 0;
		pointer-events: none;
		display: flex;
		justify-content: space-evenly;
		z-index: 0;
		span {
			display: block;
			position: relative;
			top: 0;
			bottom: 0;
			width: 1px;
			background-color:rgba(111, 111, 111, 0.18);
			z-index: 0;
		}
	}
	&.has-lines {
		*:not(.content-lines) {
			z-index: 2;
		}
	}
	&.dark-section {
		.content-lines {
			span {
				background-color:rgba(255,255,255,0.15);
			}

		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.content-row {
		&.padding-top{
			padding-top: 120px;
		}
		&.padding-bottom{
			padding-bottom: 120px;
		}
		&.padding-top-double{
			padding-top: 240px;
		}
		&.padding-bottom-double{
			padding-bottom: 240px;
		}
		.content-lines {
			left: -32px;
			right: -32px;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.content-row {
		.content-lines {
			left: -51.5px;
			right: -51.5px;
		}
	}
}

//Tablet landscape --------------------------------------------------------------------------------------
@media (min-width: 1320px) {
	.content-row {
		.content-lines {
			width: calc(100vw - var(--scrollbar-width));
			left: 50%;
			right: auto;
			transform: translateX(-50%);
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
