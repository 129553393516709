//Mobile------------------------------------------------------------------------------------------------------
#hero {
	background-color: var(--body-bg-dark);
	&.has-image {
		background-color: transparent;
		#hero-caption {
			padding-bottom: 220px;
		}

	}
	span.pre-title {
		font-size: 12px;
		text-transform: uppercase;
		color: #FFF;
		font-weight: 700;
		letter-spacing: 2.8px;
		margin-bottom: 20px;
		display: block;
		span {
			color: var(--color-primary);
		}
	}
	.hero-logo {
		width: 200px;
		margin-bottom: 40px;
	}
	h1.hero-title {
		font-weight: 600;
		line-height: 1em;
		padding-bottom: 20px;
		width: 100%;
		font-size: var(--h1-font-size);
		letter-spacing: var(--heading-letter-spacing);
		line-height: var(--heading-line-height);
		font-family: var(--heading-font);
		padding-top: 0.5vw;
		span {
			margin-top: -0.6vw;
		}
	}
	.hero-text {
		p {
			color: #FFF;
			padding-left: 8px;
			padding-top: 24px;
			font-family: var(--font-primary);
		}
		.icon {
			fill: var(--color-primary);
			flex-shrink: 0;
		}
	}
	
	&.has-video {
		.hero-title {
			span {
				span {
					transform: none !important;
					display: inline !important;
					color: var(--color-primary);
				}
			}
		}
	}
	.hero-internal-video-wrapper {
		position: relative;
		min-height: 100vh;
		&:after {
			content: '';
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.2);
			z-index: 1;
		}
	}

}
.dark-text {
	#hero {
		background-color: #FFF;
		span.pre-title {
			color: #000;
		}
		.hero-lines {
			span {
				background: rgba(111, 111, 111, 0.18);
			}
		}
		p {
			color: #000;
		}
	}
}
#hero-image-wrapper {
	&:after {
		content: '';
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.2);
	}
	video {
		min-height: 100vh;
		width: 100%;
		object-fit: cover;
	}
}
#hero-description {
	.hero-text {
		.icon {
			fill: var(--color-primary);
    		flex-shrink: 0;
		}
		p {
			padding-left: 8px;
			padding-top: 24px;
			font-family: var(--font-primary);
		}
	}
	
}
.hero-lines {
	position: absolute;
	inset: 0;
	pointer-events: none;
	display: flex;
	justify-content: space-evenly;
	span {
		display: block;
		position: relative;
		top: 0;
		bottom: 0;
		width: 1px;
		background-color:rgba(255,255,255,0.15);
	}
}
#hero-text {
	.hero-lines {
		margin-inline: 0
	}
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
	
}

//mobile only ---------------------------------------------------------------------------------------
@include media-breakpoint-down(md) {
	
	#hero-text {
		padding-left: 30px;
		padding-right: 30px;
	}
	#hero {
		h1.hero-title {
			font-size: 4rem;
		}
	}
}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	#hero-text {
		.hero-lines {
			margin-inline: 8.5px
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	#hero-description {
		//max-width: 80%;
		.hero-text {
			p {
				font-size: 28px;
			}
		}
	}
	
	#hero {
		.content-max-width {
			max-width: 80%;
		}
		&.has-image {
			#hero-caption {
				padding-bottom: 715px;
				padding-top: 240px;
			}
		}
		.hero-logo {
			width: 330px;
		}
		.hero-text {
			p {
				font-size: 28px;
			}
		}
		span.pre-title {
			font-size: 14px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	#hero {
		.hero-title-wrapper {
			margin-left: 120px;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
