:root {
	//-colors
	--body-bg: #fff;
	--body-bg-dark: #262626;
	--color-primary: #E43D30;
	--color-primary-hover: #E43D30;
	--text-color: #000;
	--text-color-dark: #fff;
	--footer-bg: var(--body-bg-dark);
	// spacing
	--padding-h-mob: 24px;
	--padding-h-desk: 72px;
	//-general
	--footer-height: 690px;
	--body-color: var(--text-color);
	--link-color: var(--color-primary);
	--border-radius: 8px;
	//-fonts
	--font-primary: 'DM Sans', sans-serif;
	--font-secondary: 'Montserrat', sans-serif;
	--font-mono: monospace;
	//-typography
	--heading-font: var(--font-secondary);
	--heading-weight: 600;
	--heading-line-height: 0.91;
	--heading-letter-spacing: -0.03em;
	--h1-font-size: clamp(3.375rem,13.333333vw,12rem);
	--h1-font-size-small: clamp(3.375rem,13.333333vw,8.8rem);
	--h2-font-size: clamp(2.5rem,9.72vw,8.75rem);
	--h3-font-size: clamp(2rem,6.3vw,3.25rem);
	--h4-font-size: clamp(1.5rem,3vw,2rem);
	--h5-font-size: 1.125rem;
	--h6-font-size: 1rem;
	--p-font-size: 1.25rem;
	--p-margin-bottom: 1em;
	--p-line-height: 1.5em;
	--p-color: var(--text-color);
	--hr-size: 1px;
	--hr-margin: 1rem;
	//-transition
	--transition-default: all 0.3s ease-in-out;
	//-icon
	--icon-size-sm: 16px;
	--icon-size: 24px;
	--icon-size-lg: 32px;
	--icon-size-xl: 40px;

	// focus outline
	--outline-color: orange;
	--outline-width: 2px;
}

body {
	--scrollbar-width: calc(100vw - 100%);
}

//mobile
@include media-breakpoint-up(sm) {
	:root {

	}
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
	:root {

	}
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	:root {

	}
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	:root {

	}
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
	:root {

	}
}
//dark theme
@media (prefers-color-scheme: dark) {
	:root {
		//-general
	}
}
