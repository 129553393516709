//Mobile------------------------------------------------------------------------------------------------------
.text-centered {
	padding: 80px 0;
	h2 {
		font-size: 20px;
		text-transform: uppercase;
		line-height: 1.57;
		letter-spacing: 2.8px;
		margin-bottom: 60px;
		&:before {
			content: "/";
			margin-right: 0.75em;
			color: var(--color-primary);
		}
	}
	p {

		font-size: var(--p-font-size);
		line-height: var(--p-line-height);
		margin: 0;
	}
	.content {
		margin: auto;
		width: 83.33333333%;
	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.text-centered {
		padding: 160px var(--padding-h-desk);
		.content {
			max-width: 850px;
		}
		p {
			font-size: 32px;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.text-centered {
		h2 {
			font-size: 20px;
		}
		.content {
			width: 66.666666%;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
