//Mobile------------------------------------------------------------------------------------------------------
.fixed-component {
	//min-height: 150vh;
	.content-row {
		//min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.fixed-component {
		min-height: 380vh;
		.content-row {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.dark-section-wrapper,
		.light-section-wrapper {
			height: 100%;
		}
		.text-centered {
			opacity: 0;	
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}