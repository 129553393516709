//Mobile------------------------------------------------------------------------------------------------------
.contact-link {
	margin-bottom: 40px;
	i {
		color: var(--color-primary);
	}
	h5 {
		font-size: 24px;
		line-height: 1.5;
		margin-bottom: 18px;
	}
	p {
		text-transform: uppercase;
		font-size: 11px;
		color: #7D7D7D !important;
		font-family: var(--font-primary);
		letter-spacing: 2.2px;
		font-weight: 700;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.contact-link {
		width: 90%;
		h5 {
			font-size: 18px;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.contact-link {
		width: 100%;
		h5 {
			font-size: 24px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
