//Mobile------------------------------------------------------------------------------------------------------

.image-text-fixed {
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	.img-container {
		position: relative;
		width: 100%;
		height: 100vh;
	}
	.img-wrapper {
		width: 100%;
		height: 100vh;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.4);
		}
		&:not(:nth-child(1)) {
			position: absolute;
			inset: 0;
			opacity: 0;
		}
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.content-container {
		padding-bottom: 50vh;
	}
	.content {
		position: relative;
		z-index: 1;
		padding: 80px 20px;
		margin-left: 32px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		&:not(:last-child) {
			margin-bottom: 200px;
		}
	}
	p {
		font-size: clamp(20px,1.94vw,24px);
		line-height: 1.21;
	}
	h2 {
		margin-bottom: 40px;
		position: relative;
		font-size: clamp(32px,3.61vw,52px);
		line-height: 0.92;
		&:before,
		&:after {
			color: var(--color-primary);
		}
		&:before {
			content: "“";
			left: -0.5em;
			top: -0.25em;
			position: absolute;
		}
		&:after {
			position: relative;
			content: "”";
			left: 0.1em;
			bottom: -0.65em;
			top: auto;
		}
	}
	.signature {
		margin-top: 32px;
		filter: invert(1);
		margin-left: -16px;
		height: 34px;
		width: auto;
	}
	.position {
		font-size: 14px;
		letter-spacing: 2.8px;
		text-transform: uppercase;
	}
	.button {
		margin-top: 120px;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.image-text-fixed {
		.img-wrapper {
			&:after {
				background-color: rgba(0,0,0,0.0);
			}
		}
		.content {
			width: 100%;
			margin: 0;
			padding: 0 80px 0 50%;
			h2,
			p {
				max-width: 500px;
			}
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.image-text-fixed {
		.content {
			padding-right: 160px;
		}
	}
}

@include media-breakpoint-down(xl) {
	.image-text-fixed {
		.img-wrapper {
			height: 100vh;
		}
		.img-container {
			height: 100vh;
			inset: 0;
		}
		.content-container {
			position: absolute;
			z-index: 10;
			width: 100%;
			padding-top:100vh;
			top: 0;
			//padding-bottom: 50vh;
			min-height: 100vh;
		}
	}
}
//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.image-text-fixed-trigger {
		height: 600vh;
	}
	.image-text-fixed {
		.content {
			min-height: 100vh;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
