//Mobile------------------------------------------------------------------------------------------------------
.card {
	overflow: hidden;
	border-radius: 20px;
	position: relative;
	.img-wrapper {
		position: relative;
		padding-top: 121.18%;
		border-radius: 20px;
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 54.15%, rgba(31, 31, 31, 0.70) 100%);
		}
	}
	.text-wrapper {
		position: absolute;
		inset: 0;
		padding: 70px 50px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		transition: all 0.5s;
		p {
			color: #FFF;
			margin: 0;
			&.name {
				font-family: var(--font-secondary);
				font-size: 24px;
				font-weight: 400;
			}
			&.role {
				font-size: 11px;
				text-transform: uppercase;
				font-family: var(--font-primary);
				letter-spacing: 2.2px;
			}
		}
	}
	.people-wrapper:not(:last-child){
		margin-bottom: 30px;
	}
	.card-overlay {
		position: absolute;
		inset: 0;
		padding: 70px 50px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 54.15%, rgba(31, 31, 31, 0.70) 100%);
		opacity: 0;
		pointer-events: none;
		transition: all 0.5s;
		span.icon-span {
			color: var(--color-primary);
			font-size: 68px;
			font-family: var(--font-secondary);
			line-height: 0.75;
		}
		p {
			margin: 0;
			color: #FFF;
			&.titolo {
				font-size: 24px; // 38
				line-height: 1.1;
				margin-bottom: 30px;
				font-family: var(--font-secondary);
				overflow: hidden;
				span {
					transform: translateY(100%);
					display: block;
					transition: all 0.3s ease-out;
				}
			}
			&.testo {
				font-size: 14px; //20
				line-height: 1.3; //35
				overflow: hidden;
				span {
					transform: translateY(100%);
					display: block;
					transition: all 0.5s ease-out;
				}
			}
		}
	}
	&.card-right {
		text-align: right;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.card {
		.card-overlay {
			span.icon-span {
				//font-size: 68px;
				line-height: 1;
			}
			p {
				&.titolo {
					font-size: 38px; // 38
					//line-height: 1.3;
					margin-bottom: 30px;
				}
				&.testo {
					font-size: 20px; //20
					line-height: 1.6; //35
				}
			}
		}
	}
}

@include media-breakpoint-down(xl) {
	.card {
		&.hovered {
			.card-overlay {
				opacity: 1;
			}
			.text-wrapper {
				opacity: 0;
			}
			p.testo span,
			p.titolo span {
				transform: translateY(0);
			}
		}
		.card-overlay {
			padding: 24px;
			p {
				line-height: normal;
			}
		}
	}
}
//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.card {
		&.card-left {
			.card-overlay, .text-wrapper {
				padding-right: 110px;
			}
		}
		&.card-right {
			.card-overlay, .text-wrapper {
				padding-left: 110px;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.card {
		&:hover {
			.card-overlay {
				opacity: 1;
			}
			.text-wrapper {
				opacity: 0;
			}
			p.testo span,
			p.titolo span {
				transform: translateY(0);
			}
		}
	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
