//Mobile------------------------------------------------------------------------------------------------------
header {
	.hidden-logo {
		opacity: 0;
		transition: all 0.3s;
		pointer-events: none;
	}
	&.over-sidebar {
		.hidden-logo {
			opacity: 1 !important;
			pointer-events: all;
		}
	}
	#logo img {
		height: 37px;
	}
	.button-wrap.right.menu.burger-lines {
		top: 4px;
	}
}


.burger-lines {
	#menu-burger {
		height: 17px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0;
		width: auto;
		.icon {
			fill: #FFF;
			position: absolute;
			top: -3px;
			left: 50%;
			transform: translateX(-50%);
			opacity: 0;
			pointer-events: none;
			transition: all 0.3s;
		}
		span {
			&.line {
				transform: none;
				height: 1px;
				width: 40px;
				position: relative;
				transition:all 0.3s;
			}
			&:nth-child(1) {
				top: inherit;
			}
			&:nth-child(2) {
				top: inherit;
			}
			&:nth-child(3) {
				display: block;
				width: 28px;
			}
		}
		//-menu open
		&.open {
			span.line {}
            .icon {
                opacity: 1;
            }
            span {
                opacity: 0;
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3) {
					top: inherit;
					transform: none;
				}
            }
			
        }
	}
	
}
.button-wrap.right.burger-lines {
	right: auto;
}

#burger-wrapper {
	justify-content: space-between;
	height: 17px;
}
.light-content .white-header .dark-content-header #logo img.black-logo {
	opacity: 0 !important;
}
.light-content .white-header .dark-content-header #logo img.white-logo {
	opacity: 1 !important;
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	header {
		#header-container {
			padding-left: 44px;
			padding-right: 44px;
		}

	}
	
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.fullscreen-menu .flexnav li a {
		font-size: calc(1rem + 5.5vw);
	}
	.fullscreen-menu .flexnav {
		width: 80%;
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.burger-lines:hover #menu-burger{
		span {
			&:nth-child(1){

			}
			&:nth-child(2){
				width: 27px;
			}
			&:nth-child(3) {
				width: 40px;
			}
		}
	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	
}