//Mobile------------------------------------------------------------------------------------------------------
.logos-list {
	padding: 80px var(--padding-h-mob) 0;
	position: relative;
	h2 {
		font-size: var(--h2-font-size);
		margin-bottom: 80px;
		span {
			display: inline-block;
			margin-right: 0.25em;
		}
	}
	.logos {
		list-style-type: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		//gap: 16px;
		li {
			width: 50%;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			padding: 0;
			border-bottom: 1px solid #CCC;
			&:nth-last-child(-n+2) {
				border-bottom: none;
			}
			&:nth-last-child(-n+3) {
				border-bottom: none;
			}
			min-height: calc(calc(100vw - 48px) / 2);
		}
		img {
			max-width: 120px;
			max-height: 100px;
			height: auto;
			flex-shrink: 0;
		}
		&.lines {
			min-height: auto;
			li {
				padding: 0;
				height: 300px;
				border-bottom: none;
				border-right: none !important;
				&:nth-child(n+3) {
					display: none;
				}
			}
		}
	}
	.button-zone {
		position: absolute;
		bottom: 150px;
		transform: translateX(-50%);
		left: 50%;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}

//Mobile only ---------------------------------------------------------------------------------------
@include media-breakpoint-down(md) {
	.logos-list {
		.logos {
			li {

				&:nth-child(odd) {
					border-right: 1px solid #CCC;
				}
				&.desk-only {
					display: none;
				}
			}
		}

	}
}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.logos-list {
		padding: 160px var(--padding-h-desk) 0;
		h2 {
			margin-bottom: 160px;
		}
		.logos {
			li {
				min-height: calc(calc(100vw - var(--padding-h-desk)) / 3);
				width: 33.3333%;
				&:not(:nth-child(3n)) {
					border-right: 1px solid #CCC;
				}
				&:nth-last-child(-n+3) {
					//border-bottom: none;
				}
			}
			&.lines {
				li {
					height: 400px;
					&:nth-child(n+4) {
						display: none;
					}
				}
			}
		}
		.button-zone {
			bottom: 220px;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	@include media-breakpoint-up(md) {
		.logos-list {
			.logos {
				li {
					width: 20%;
					min-height: calc(calc(100vw - var(--padding-h-desk)) / 5);
					&:nth-child(3n) {
						border-right: 1px solid #CCC;
					}
					&:nth-child(5n) {
						border-right: none;
					}
					&:nth-last-child(-n+5) {
						border-bottom: none;
					}
				}
				&.lines {
					li {
						display: block !important;
						border: none;
					}
				}
			}

		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
