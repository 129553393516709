//Mobile------------------------------------------------------------------------------------------------------
.people-parallax {
	.card {
		margin-bottom: 30px;
		img {
			transform: scale(1.3);
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.people-parallax {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-left: 5%;
		.card {
			width: 50%;
			flex-shrink: 0;
			&:not(:last-child) {
				margin-bottom: 300px;
			}
			&:nth-child(odd) {
				margin-right: -2.5%;
			}
			&:nth-child(even) {
				margin-left: -2.5%;
				transform: translateY(70%);
			}
			&:last-child {
				margin-top: -15%;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}