//Mobile------------------------------------------------------------------------------------------------------
.img-h-scroll {
	position: relative;
	overflow: hidden;
	img {
		width: 100%;
		opacity: 0;
	}
	h2 {
		span {
			display: inline;
			margin-top: 0;
		}
	}
	.full-link {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		z-index: 2;
	}
	.img-wrapper {
		margin: auto;
		overflow: hidden;
		background-repeat: no-repeat;
		background-size: 100vw;
		background-position: center center;
		height: auto;
		&.zoom-in {
			border-radius: 32px;
			width: 80%;
		}
		&.zoom-out {
			border-radius: 0;
			width: 100%;
		}
		&.img-small {
			//height: 160px;
		}
	}
	.title-moving-outer {
		position: absolute;
		bottom: 30px;
		h2 {
			color: #FFF;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}

@include media-breakpoint-down(md) {
	.img-h-scroll {
		.title-moving-outer {
			bottom: 120px;
			h2 {
				font-size: 4rem;
			}
		}
		
	}
}
//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.img-h-scroll {
		.img-wrapper {
			&.img-small {
				//height: 70vh;
			}
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.img-h-scroll {
		.title-moving-outer {
			bottom: 100px;
		}
		.img-wrapper {
			//height: 100vh;
			&.img-small {
				//height: 70vh;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
