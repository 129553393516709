// modern-css-reset
@import "../../node_modules/modern-css-reset/src/reset.css";



//custom mixins
@import "mixins";

// bs 5 setup
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/helpers/visually-hidden";

// bs 5
@import "../../node_modules/bootstrap/scss/utilities/api";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/transitions";

//collapse
@import "../../node_modules/bootstrap/scss/nav";

//css vars
@import "vars";

//fonts local (do not import if google fonts are used)
@import "fonts_local";

// general rules
@import "general";
@import "typography";
@import "focus";

/* stylelint-disable */
//components
@import "../components/button/button.scss";
@import "../components/card/card.scss";
@import "../components/color-change/color-change.scss";
@import "../components/contact-link/contact-link.scss";
@import "../components/content-row/content-row.scss";
@import "../components/cta-big/cta-big.scss";
@import "../components/fixed-component/fixed-component.scss";
@import "../components/footer/footer.scss";
@import "../components/header/header.scss";
@import "../components/hero/hero.scss";
@import "../components/icon/icon.scss";
@import "../components/image-text-fixed/image-text-fixed.scss";
@import "../components/image-text-pinned/image-text-pinned.scss";
@import "../components/img-h-scroll/img-h-scroll.scss";
@import "../components/logos-list/logos-list.scss";
@import "../components/magic-cursor/magic-cursor.scss";
@import "../components/map/map.scss";
@import "../components/moving-title/moving-title.scss";
@import "../components/nav/nav.scss";
@import "../components/news/news.scss";
@import "../components/people-parallax/people-parallax.scss";
@import "../components/portfolio-section/portfolio-section.scss";
@import "../components/preloader/preloader.scss";
@import "../components/reveal-section/reveal-section.scss";
@import "../components/text-centered/text-centered.scss";
@import "../components/text-parallax/text-parallax.scss";
@import "../components/text-reveal/text-reveal.scss";
@import "../components/title-reveal/title-reveal.scss";
@import "../components/title-text/title-text.scss";
@import "../components/video-scroll/video-scroll.scss";
/* stylelint-enable */
