//Mobile------------------------------------------------------------------------------------------------------


.video-scroll {
	height: 100vh;
	position:relative;
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&:after {
		content: '';
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.2);
		z-index: 1;
	}
	.text-content {
		position: absolute;
		inset: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 30px 24px 80px;
		box-sizing: border-box;
		z-index: 2;
		img {
			width: 32px;
		}
	}
	.claim-container {
		position: relative;
		h2 {
			font-size: clamp(3rem,9.72vw,8.75rem);
		}
		h3 {
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 3.8px;
			text-transform: uppercase;
			font-family: var(--font-primary);
			white-space: nowrap;
			margin-top: 5px;
		}
		span {
			display: inline;
			color: var(--color-primary);
		}
	}
	.claim-content {
		position: absolute;
		transform: translateX(-50%) translateY(-50%);
		&:not(:first-child) {
			opacity: 0;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.video-scroll {
		.claim-container {

			h3 {
				font-size: 19px;
			}
		}
	}
}

@include media-breakpoint-down(xl) {
	.video-scroll-trigger {
		//height: 300vh;
	}
}
//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.video-scroll-trigger {
		position: relative;
		z-index: 10;
		padding-bottom: 200vh;
		//display: none;
		.video-scroll-content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 300vh;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
