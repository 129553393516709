//Mobile------------------------------------------------------------------------------------------------------
.button {
	&.button-border {
		background-color: var(--color-primary) !important;
		padding: 30px 50px;
		border-radius: 50px;
		color: #FFF;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 1.6px;
		border: 0 !important;
		height: auto;
		line-height: inherit;
		a,button {
			display: block;
			overflow: hidden;
			line-height: 1.3em;
		} 
		span {
			padding: 0;
			color: #FFF;
			white-space: nowrap;
		}
	}
}
.light-content .light-section {
	.button {
		color: #fff;
	}
}
.button-link .button-icon i {
	border: 0;
}
.button-wrap.right .button-text span {
	font-size: 16px;
	font-family: var(--font-primary);
	font-weight: 700;
	color: #262626;
}
.button-link .button-text span::before {
	background-color: #262626;
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
