//Mobile------------------------------------------------------------------------------------------------------
.title-moving-outer {
	h2 {
		font-size: var(--h1-font-size);
		line-height: 1.4em;
		&.title-small-text {
			font-size: var(--h1-font-size-small);
		}
		span {
			display: inline;
			margin-top: 0;
			color: var(--color-primary);
		}
		&.title-moving-forward {
			margin-left:60vw;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
