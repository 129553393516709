//Mobile------------------------------------------------------------------------------------------------------
body {
	background-color: var(--body-bg);
	color: var(--body-color);
}

a {
	color: var(--link-color);
}

button {
	margin: 0;
}
.list-unstyled {
	padding:0;
	margin: 0;
	list-style-type: none;
}

#main {
	margin-bottom: var(--footer-height);
}

.light-content {
	.light-section {
		p,ul,ol {
			color: var(--text-color);
		}
	}
}
.light-section-wrapper,
.dark-section-wrapper {
	position: relative;
}
.dark-section {
	p,ul,ol {
		color: var(--text-color-dark);
	}
}

.visually-hidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.color-primary {
	color: var(--color-primary);
}

.preloader-list {
	font-family: var(--heading-font);
	font-weight: var(--heading-weight);
	line-height: var(--heading-line-height);
	letter-spacing: var(--heading-letter-spacing);
}
.opacity-1 {
	opacity: 1 !important;
}
.pagination-wrapper {
	margin-bottom: 32px;
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	button,
	input[type="checkbox"] + label,
	input[type="radio"] + label {
		&:hover {
			cursor: pointer;
		}
	}
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
}
