//Mobile------------------------------------------------------------------------------------------------------

#content-scroll {
	pointer-events: none;
	#main > * {
		pointer-events: all;
	}
}

.footer {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	bottom: 0;
	top: auto;
	width: 100%;
	z-index: 0;
	background-color: var(--body-bg-dark);
	color: #fff;
	padding: 0 var(--padding-h-mob) 40px;
	.footer-logo {
		display: flex;
		justify-content: center;
		padding: 80px 40px 80px;
		img {
			width: 100%;
			max-width: 386px;
			height: auto;
		}
	}
	.footer-bottom {
		text-align: center;
	}
	p, .footer-links {
		font-size: 16px;
		margin: 0;
		padding: 0;
	}
	p {
		//color: #fff;
	}
	.footer-links {
		list-style-type: none;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 32px;
		line-height: 1;
		li {
			&:not(:last-child) {
				margin-right: var(--padding-h-mob);
			}
			margin-bottom: 0;
		}
	}

	.socials-wrap {
		width: 132px;
		.socials-icon {

		}
		.socials-text {
			font-size: 16px;

		}
		.socials {

		}
	}
	.follow {
		color: #fff;
	}
	.lang-change li:not(:last-child) {
		margin-right: 8px;
  	}
	.lang-change {
		margin-top: 24px;
		a {
			-webkit-text-stroke: 0.3px #FFF;
			-webkit-text-fill-color: transparent;
			transition: all 0.3s;
		}
		a.active {
			color: #FFF;
			-webkit-text-stroke: 0.3px #FFF;
			-webkit-text-fill-color: #FFF;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}

//Mobile only ---------------------------------------------------------------------------------------
@include media-breakpoint-down(md) {
	.footer {
		.footer-links {
			flex-wrap: wrap;
			flex-direction: column-reverse;
			li:not(.follow) {
				width: 33.333333%;
				margin-inline: 0 !important;
			}
			.follow {
				margin-top: 1rem;
				width: 100%;
				display: flex;
				justify-content: center;
				.align-items-center {
					& > span {
						margin-right: 1rem;
					}
				}
				a {
					margin-right: 1rem !important;
					margin-left: 0 !important;
					&:last-child {
						margin-right: 0 !important;
					}
				}
			}
			.icon {
				width: 44px;
				height: 44px;
			}
		}
	}
}



//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.footer {
		padding: 0 var(--padding-h-desk) 60px;
		.footer-logo {
			padding: 160px 20px 150px;
		}
		.lang-link {
			margin-right: 0 !important;
			a {
				-webkit-text-stroke: 0.3px #FFF;
				-webkit-text-fill-color: transparent;
				transition: all 0.3s;
			}
		}
	}
}

//Mobile & Tablet portrait only ---------------------------------------------------------------------------------------
@media (max-width: 1023px) {
	.footer {

		.right {
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
			justify-content: center;
		}
		.socials-wrap {
			display: flex;
			flex-wrap: nowrap;
			float: none;
			width: auto;
			height: auto;
			margin: 0;
			right: 0;
			left: 0;
			transform: none !important;
			margin-top: 10px;
			.socials-icon {

			}
			.socials-text {
				display: block;
				transform: none !important;
				flex-shrink: 0;
				height: auto;
				line-height: 1;
				margin-right: 10px;
			}
			.socials {
				margin: 0;
				display: flex;
				flex-wrap: nowrap;
				line-height: 1;

				li {
					margin: 0;
					height: auto;
					width: auto;
					line-height: 1;
					&:not(:last-child) {
						margin-right: 10px;
					}
					a {
						height: auto;
						width: auto;
						line-height: 1;
						font-size: 16px;
					}
				}

			}
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@media (min-width: 1024px) {
	.footer {
		.lang-link {
			font-size: 30px;
		}
		.footer-bottom {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}
		.footer-links  {
			margin-top: 30px;
		}
		p {
			margin-top: 28px;
		}
		.socials-wrap {
			height: auto;
		}
		.lang-change a{
			font-size: 30px;
			&:hover {
				color: #FFF;
				-webkit-text-stroke: 0.3px #FFF;
				-webkit-text-fill-color: #FFF;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
