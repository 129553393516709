//Mobile------------------------------------------------------------------------------------------------------
.main-nav {
	.bottom-nav {
		li:not(:last-child) {
			margin-right: 8px;
			span {
				color: var(--color-primary);
			}
		}
		a {
			-webkit-text-stroke: 0.3px #FFF;
			-webkit-text-fill-color: transparent;
			transition: all 0.3s;
			&.active {
				color: #FFF;
				-webkit-text-stroke: 0.3px #FFF;
				-webkit-text-fill-color: #FFF;
			}

		}
		position: fixed;
		bottom: 0;
		width: 100%;
		padding-left: 30px;
		padding-right: 30px;
	}
}
.fullscreen-menu.invert-header .flexnav li a {
	line-height: 1.2em;
	overflow: hidden;
	padding-bottom: 0.5vw;
	font-weight: 600;
	margin-top: -0.5vw;
	display: inline-block;
	width: auto;
	.before-span {
		padding-inline: 0.5em;
		&:before {
			position: absolute;
			left: 0em;
			content: '/';
			color: var(--color-primary);
		}
	}
}
.fullscreen-menu .flexnav li:hover a span {
	transform: translateY(-110%);
}
.fullscreen-menu .flexnav li a span {
	font-family: var(--font-secondary);
}
.fullscreen-menu .flexnav li a {
	color: #FFF;
}
@media only screen and (max-width: 1024px) {
	header {
		nav {
			height: 100vh !important;
		}
	}


}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}

//Mobile only ---------------------------------------------------------------------------------------
@include media-breakpoint-down(md) {
	.flexnav li a {
		font-size: 2.25rem;
	}
}



//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.main-nav {
		.bottom-nav {
			a {
				font-size: 30px;
			}
		}
	}
	.fullscreen-menu.invert-header .flexnav li a {
		line-height: 1em;
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.main-nav {
		.bottom-nav {
			width: 80%;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.fullscreen-menu.invert-header .flexnav li a{
		.before-span {
			&:before {
				transition: color 0.2s;
				color: var(--text-color-dark);
			}
		}
		&:hover {
			.before-span {
				&:before {
					color: var(--color-primary);
				}
			}
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.fullscreen-menu.invert-header .flexnav li a{
		position: relative;
		&:before {
			//content: '';
			display: block;
			height: 12px;
			background-color: var(--color-primary);
			position: absolute;
			left: 0;
			width: 100%;
			top: 50%;
			margin-top: -10px;
			transform: scaleX(0);
			transform-origin: left;
			transition: all 0.3s ease-in;
		}
		&:hover {
			&:before {
				transform: scaleX(1);
			}
		}

	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.main-nav {
		.bottom-nav {
			a:hover {
				color: #FFF;
				-webkit-text-fill-color: #FFF;
			}
		}
	}
}
